import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import every from 'lodash/every';
import some from 'lodash/some';
import any from 'lodash/some';
import assign from 'lodash/assign.js';

import deepLinkParse from './deep-link-parser.js';

import Begin from './begin.jsx';
import Customer from './customer/customer.jsx';
import BasketActionCreators from '../../actions/BasketActionCreators.js';
import Student from './student/student.jsx';
import HowToStudy from './how-to-study.jsx';
import TimeZone from './what-timezone.jsx';
import WhatRegion from './what-region.jsx';
import WhatCourse from './what-course.jsx';
import WhatSessionOptions from './what-session-options.jsx';
import WhatSite from './what-site.jsx';
import Upsells from './upsells.jsx';
import WhatNext from './what-next.jsx';
import Billing from './billing.jsx';
import Flywire from './flywire.jsx';
import NoCoursesAvailable from './no-courses-available.jsx';
import StepIndicator from './step-indicator.jsx';
import ErrorBoundary from './error-boundary.jsx';

var End = createReactClass({ render: function() { return <div><h1>Le Fin</h1><p>That's all for now folks</p></div>; } });

import isMatch from 'lodash/isMatch';
import forEach from 'lodash/forEach';
import rest from 'lodash/rest';
import set from 'lodash/set';
import get from 'lodash/get';
var NOT_THERE = "__NOT_THERE__";
import storage from '../../utilities/LocalStorage.js';
import BasketStore from '../../stores/BasketStore.js';

import CourseStore from '../../stores/CourseStore.js';

var ApplyWizard = createReactClass({
    displayName: 'ApplyWizard',
    getInitialState: function () {
        var tryGetFromStorage = deserialize();
        if (tryGetFromStorage) {
            tryGetFromStorage.Component = React.createRef();
            return tryGetFromStorage;
        }

        return this.getDefaultState();
    },

    getDefaultState: function() {
        // if you add to this make sure serialization and the set() method work
        return {
            CurrentComponentDisplayName: Begin.displayName,
            Component: React.createRef(),
            HasStartedWizard: false,
            IsUserCustomer: true, // used to be editable, now is not
            Customer: {},
            Student: {},
            CurrentItemId: false,
            Booking: {},
            Accommodation: {},
            SkipUpsell: false,
            SelectedUpsellOptions: [],
            PaymentError: false,
            hasClickedPayDeposit: false,
            StudyMethod: 'onsite',
            TimeZone: null,
            Region: null,
            Course: null,
            SessionIds: null,
            SessionDates: null,
            Sessions: null,
            SessionPricing: null,
            Intensity: null,
            CourseOptionSolutions: null,
            StudentTooOldOrTooYoung: false
        };
    },

    set: function (state, callback) {
        // when something updates the state of the wizard we need to reset other bits of the wizard
        // i.e. if you change the course, the sessionIds no longer match so they need to be reset
        // this bit of code sorts that
        var derivedState = assign({}, state);
        forEach(consequentResetterFor, function(resetter, prop) {
            if (!derivedState.hasOwnProperty(prop)) {
                return;
            }

            var oldValue = get(this.state, prop, NOT_THERE);
            var newValue = get(derivedState, prop, NOT_THERE);
            if (oldValue !== NOT_THERE
                // and the current prop has been set
                && newValue !== NOT_THERE 
                // and they have been changed
                && ((typeof oldValue == "object" && ((resetter.hasOwnProperty('match') && !resetter.match(oldValue, newValue)) || (!resetter.hasOwnProperty('match') && !isMatch(oldValue, newValue))))
                    || (typeof oldValue != "object" && oldValue !== newValue))) {
                        // iterate through the properties, if they're being set
                        // in this update we ignore them, otherwise we reset them
                        forEach(resetter.reset, function(resetProp) {
                            if (derivedState.hasOwnProperty(resetProp)) {
                                return;
                            }

                            var reset = resetFunc(this, resetProp);
                            derivedState = assign(derivedState, reset);
                        }.bind(this))
                    }
        }.bind(this));

        return this.setState(derivedState, callback);
    },
    
    componentDidMount: function () {
        CourseStore.addChangeListener(this.updateCourseList);
    },

    componentWillUnmount: function () {
        CourseStore.removeChangeListener(this.updateCourseList);
    },

    updateCourseList: function () {
        var courses = CourseStore.getCourses();
        if (this.state.Student.DateOfBirth) {
            var result = deepLinkParse(getCoursesForAgeGroup(courses, this.state.Student.DateOfBirth), this.state) || {};
            if (result.hasOwnProperty('Region')) {
                this.set(result);
            }
        }

        function getCoursesForAgeGroup(courses, studentDob) {
            return filter(courses, function (c) {
                return any(c.Dates, function (cd) {
                    var studentAgeOnDate = moment.utc(cd.From).diff(studentDob, 'years');
        
                    function isStudentAgeEligible(ag) {
                        return (!ag.LowerBound || ag.LowerBound <= studentAgeOnDate) && (!ag.UpperBound || studentAgeOnDate <= ag.UpperBound);
                    }
        
                    return any(c.AgeGroups, isStudentAgeEligible);
                });
            });
        }
    },

    componentDidUpdate: function(prevProps, prevState) {
        serialize(this.state);
    },

    getCurrentComponent: function() {
        return this.getComponentByName(this.state.CurrentComponentDisplayName);
    },

    getComponentByName: function(name) {
        var availableComponents = getAvailableComponents(this.state);
        return find(availableComponents,
            function (component) {
                return component.displayName === name;
            }.bind(this));
    },

    getCurrentComponentIdx: function () {
        return this.getComponentIdx(this.state.CurrentComponentDisplayName);
    },

    getComponentIdx: function (name) {
        var availableComponents = getAvailableComponents(this.state);
        for (var i = 0; i < availableComponents.length; i++) {
            if (availableComponents[i].displayName === name) {
                return i;
            }
        }

        return -1;
    },

    nextQuestion: function (callback) {        
        if (!this.state.Component.current.hasOwnProperty("isValid") ||
            !(this.state.Component.current.isValid instanceof Function)) {
            throw "Component must have an isValid() method";
        }

        if (!this.state.Component.current.isValid()) {
            return;
        }

        if (this.state.Component.current.hasOwnProperty('onSubmit')) {
            if (!this.state.Component.current.onSubmit()) {
                return;
            }
        }

        var components = getAvailableComponents(this.state);
        var currentIdx = this.getCurrentComponentIdx();
        var componentDisplayName = currentIdx < components.length - 1 ? components[currentIdx + 1].displayName : this.state.CurrentComponentDisplayName;
        
        this.jump(componentDisplayName, callback);        
    },

    previousQuestion: function() {
        var components = getAvailableComponents(this.state);
        var currentIdx = this.getCurrentComponentIdx();
        var componentDisplayName = currentIdx > 0 ? components[currentIdx - 1].displayName : this.state.CurrentComponentDisplayName;

        this.jump(componentDisplayName);
    },

    jumpToBilling: function () {
        this.resetCourse();
        this.jump(Billing);
    },

    jumpToWhatNext: function(preventCourseUpdate) {
        this.jump(WhatNext, this.resetCourse, preventCourseUpdate);
    },
    
    newWizardForStudent: function (itemId) {
        var course = BasketStore.getCourseByItemId(itemId);
        this.resetCourse();
        this.setState({ 
            Student: course.Student
        }, () => this.jump('WhatRegion'));
    },

    newWizardForNewStudent: function () {
        this.resetCourse();
        this.resetStudent();
        this.jump(Student);
    },

    reset: function() {
        this.setState(this.getDefaultState());
        global.localStorage.removeItem('__ora_widget_apply_wizard');
    },

    jump: function (component, callback, preventCourseUpdate) {
        if (typeof component === "string") {
            component = this.getComponentByName(component);
        }

        var func = () => {
            if (component.displayName === WhatNext.displayName && !preventCourseUpdate) {
                var currentComponent = this.getCurrentComponent();
                var creatableComponents = [WhatCourse, TimeZone, WhatSessionOptions, WhatSite, Upsells];
                if (some(creatableComponents, c => c.displayName === currentComponent.displayName)) {
                    var price = this.getPrice();
                    var itemId = new Date().getTime() + '_';                
                    BasketActionCreators.addCourse(
                        this.state.Course,
                        this.state.Intensity,
                        this.getLineItemOptions(),
                        this.state.Region,
                        this.state.Student,
                        price.PriceExcludingDiscount,
                        price.PriceIncludingDiscount,
                        price.Deposit,
                        this.state.TimeZone,
                        itemId);

                    if (this.state.SelectedUpsellOptions && this.state.SelectedUpsellOptions.length) {
                        forEach(this.state.SelectedUpsellOptions,
                            function(upsellOption) {
                                BasketActionCreators.addCourse(
                                    upsellOption.LineItem.Course,
                                    upsellOption.LineItem.Intensity,
                                    [],
                                    null,
                                    this.state.Student,
                                    upsellOption.Price,
                                    upsellOption.Price,
                                    upsellOption.Deposit,
                                    null,
                                    null,
                                    itemId);
                            }.bind(this));
                    }

                    if (this.state.CurrentItemId) {
                        BasketActionCreators.removeCourse(this.state.CurrentItemId);
                    }
                }
            }

            var state = { CurrentComponentDisplayName: component.displayName };
            this.setState(state, function() {
                scrollTo(0, 0);
                if (callback && typeof callback === "function") {
                    callback();
                }
            });
        }

        if (!document.startViewTransition) {
            setTimeout(func, 0);
            return;
        }
        
        // With a transition:        
        var currentIdx = this.getCurrentComponentIdx();
        var newIdx = this.getComponentIdx(component.displayName);
        this.writeTransition(newIdx > currentIdx);
        var transition = document.startViewTransition(func);              
    },

    writeTransition: function (ltr) {
        var id = 'view-transition';
        var style = document.getElementById(id);
        if (style) {
            style.remove();
        }

        style = document.createElement('style');
        style.id = id;
        style.innerHTML = `
        @keyframes fade-in {
            from { opacity: 0; }
          }
          
          @keyframes fade-out {
            to { opacity: 0; }
          }
          
          @keyframes slide-from-right {
            from { transform: translateX(300px); }
          }
          
          @keyframes slide-to-left {
            to { transform: translateX(-300px); }
          }
          
          @keyframes slide-from-left {
            from { transform: translateX(-300px); }
          }
          
          @keyframes slide-to-right {
            to { transform: translateX(300px); }
          }
          
          ::view-transition-old(root) {
            animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
              300ms cubic-bezier(0.4, 0, 0.2, 1) both ${(ltr ? 'slide-to-left' : 'slide-to-right')} ;
          }
          
          ::view-transition-new(root) {
            animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
              300ms cubic-bezier(0.4, 0, 0.2, 1) both ${(ltr ? 'slide-from-right' : 'slide-from-left')};
          }          
        `;
        document.head.append(style);
    },

    resetCourse: function() {
        var resetState = resetFunc(this,
            "CurrentItemId",
            "StudyMethod",
            "TimeZone",
            "Region",
            "Course",
            "SessionIds",
            "SessionDates",
            "Sessions",
            "SessionPricing",
            "Intensity",
            "CourseOptionSolutions",
            "Accommodation",
            "SelectedUpsellOptions",
            "SkipUpsell");
        this.setState(resetState);
    },

    resetStudent: function() {
        var resetState = resetFunc(this, "Student");
        this.setState(resetState);
    },

    getOptionsSolution: function () {
        if (!this.state.Course.HasCourseBreakdown) {
            return null;
        }

        if (this.state.Accommodation.IsNonResidential) {
            // just take the first solution if non-residential
            return this.state.CourseOptionSolutions[0];
        }

        // iterate through all the solutions until we find one
        // that contains the correct session/site mix 
        // according to the desired site choices
        return find(
            this.state.CourseOptionSolutions,
            function (solution) {
                return every(this.state.Sessions,
                    function(session) {
                        var sessionSite = this.state.Accommodation[session.SessionId];
                        return some(
                            solution,
                            function(courseOptionSlot) {
                                return courseOptionSlot.Session.SessionId === session.SessionId &&
                                    courseOptionSlot.Site.SiteId === sessionSite.SiteId;
                            });
                    }.bind(this));
            }.bind(this));
    },

    getLineItemOptions: function() {
        return map(this.state.Sessions,
            function(session) {
                return {
                    Session: session,
                    IsNonResidential: this.state.Course.CourseType.StudyMethod === 1 ||
                        this.state.Accommodation.IsNonResidential,
                    Site: this.state.Course.CourseType.StudyMethod === 0 &&
                        !this.state.Accommodation.IsNonResidential &&
                        this.state.Accommodation.hasOwnProperty(session.SessionId)
                        ? this.state.Accommodation[session.SessionId]
                        : null,
                    Options: this.state.Course.HasCourseBreakdown
                        ? filter(
                            this.getOptionsSolution(),
                            function(availability) {
                                return availability.Session.SessionId === session.SessionId;
                            })
                        : null
                };
            }.bind(this));
    },

    getPrice: function() {
        var lineItem = this.getLineItem();
        if (some(lineItem.Options,
            function(option) {
                return option.IsNonResidential;
            })) {
            return {
                PriceIncludingDiscount: this.state.SessionPricing.NonResidentialPriceIncludingDiscount || this.state.SessionPricing.PriceIncludingDiscount,
                PriceExcludingDiscount: this.state.SessionPricing.NonResidentialPriceExcludingDiscount || this.state.SessionPricing.PriceExcludingDiscount,
                Deposit: this.state.SessionPricing.Deposit
            };
        }

        return {
            PriceIncludingDiscount: this.state.SessionPricing.PriceIncludingDiscount,
            PriceExcludingDiscount: this.state.SessionPricing.PriceExcludingDiscount,
            Deposit: this.state.SessionPricing.Deposit
        }
    },

    getLineItem: function () {
        return {
            Course: this.state.Course,
            Student: this.state.Student,
            Intensity: this.state.Intensity,
            Options: this.getLineItemOptions(),
            Region: this.state.Region
        };
    },
    
    render: function() {
        var component = this.getCurrentComponent();              
        
        return <React.Fragment>
            <header className="bg-white shadow-lg z-50 sticky top-0">
            <div className="container mx-auto text-center py-3">
                <a href="https://www.oxford-royale.com" title="Navigate to homepage" rel="home">
                    <img src="https://www.oxford-royale.com/wp-content/themes/ora2021/static/images/ora-logo.png" 
                        srcSet="https://www.oxford-royale.com/wp-content/themes/ora2021/static/images/ora-logo.png 1x, https://www.oxford-royale.com/wp-content/themes/ora2021/static/images/ora-logo@2x.png 2x" 
                        alt="Oxford Royale Academy" 
                        style={{width:'21.25rem', 'objectFit': 'contain', 'maxWidth': '100%'}}
                        className="inline" 
                        width="382" 
                        height="75" />
                </a>
            </div>
            <div className="truncate bg-brand-primary py-1 text-gray-100 text-center">
                <span className="hidden text-lg md:inline">Application Form for Oxford Royale's 2024 Courses</span>
                <span className="md:hidden">Apply for Oxford Royale's 2024 Courses</span>
            </div>
        </header>
            <div id="ApplyWizard" className="tailwind mb-24">
                <div className={'container  mx-auto px-4 md:px-6 py-4 flex items-stretch mt-4 ' + (this.state.CurrentComponentDisplayName === Begin.displayName ? 'w-screen' : this.state.CurrentComponentDisplayName === WhatNext.displayName ? 'w-full max-w-screen-xl' : 'w-full max-w-3xl')}>
                    <div className="flex flex-col min-h-full w-full md:flex-row md:items-stretch md:space-x-6 lg:space-x-12">
                        <div className={'apply-form-container flex-auto mb-16 lg:mb-0'}>
                            <ErrorBoundary>
                            { this.state.CurrentComponentDisplayName !== Begin.displayName 
                                ? <StepIndicator wizard={this}></StepIndicator>
                                : false
                            }
                            <div className={'wizard-component ' + (this.state.CurrentComponentDisplayName !== Begin.displayName ? 'wizard-question' : 'wizard-begin')}>
                                {React.createElement(component, { wizard: this, ref: this.state.Component })}
                            </div>
                            </ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
});


function getAvailableComponents (state) {
    var components = [];
    components.push(Begin);
    components.push(Customer);
    components.push(Student);

    if (!state.StudyMethod) {
        components.push(HowToStudy);
    }

    if (state.StudentTooOldOrTooYoung) {
        components.push(NoCoursesAvailable)
    }

    if (state.StudyMethod === "onsite") {
        components.push(WhatRegion);
    }

    components.push(WhatCourse);
    if (state.StudyMethod === 'online') {
        components.push(TimeZone);
    }

    if (state.Course && state.Course.HasCourseBreakdown) {
        components.push(WhatSessionOptions);
    }

    if (state.StudyMethod === "onsite") {
        components.push(WhatSite);
    }

    if (!state.SkipUpsell) {
        components.push(Upsells);
    }

    components.push(WhatNext);
    components.push(Billing);
    components.push(Flywire);
    components.push(End);
    return components;
}

function serialize(state) {
    if (!global.localStorage || global.OraWidgets.Options.IsStorageDisabled) {
        return;
    }
    if (!state) {
        return;
    }

    storage.setItem('__ora_widget_apply_wizard',
        JSON.stringify({
            HasStartedWizard: state.HasStartedWizard,
            CurrentComponentDisplayName: state.CurrentComponentDisplayName,
            IsUserCustomer: state.IsUserCustomer,
            Customer: state.Customer,
            CurrentItemId: state.CurrentItemId,
            Student: state.Student,

            // Booking-y stuff
            StudyMethod: state.StudyMethod,
            Region: state.Region,
            Course: state.Course,
            SessionIds: state.SessionIds,
            SessionDates: state.SessionDates,
            Sessions: state.Sessions,
            SessionPricing: state.SessionPricing,
            Intensity: state.Intensity,
            TimeZone: state.TimeZone,
            CourseOptionSolutions: state.CourseOptionSolutions,
            Accommodation: state.Accommodation,
            SkipUpsell: state.SkipUpsell,
            SelectedUpsellOptions: state.SelectedUpsellOptions,
            StudentTooOldOrTooYoung: state.StudentTooOldOrTooYoung,
        }));

}

function deserialize() {
    if (!global.localStorage || global.OraWidgets.Options.IsStorageDisabled) {
        return;
    }

    var state = JSON.parse(storage.getItem('__ora_widget_apply_wizard'));
    if (state) {
        if (state.Student && state.Student.DateOfBirth) {
            state.Student.DateOfBirth = moment.utc(state.Student.DateOfBirth);
        }

        if (state.SessionDates) {
            state.SessionDates = map(state.SessionDates, function (d) { return moment.utc(d); });
        }
    }
    return state;
}

// if any particular state changes, what other things should be reset? This has the answers.
// These cascade in a tree of causality. TODO be really smart and batch them into one reset/setState call
var consequentResetterFor = {
    "Student.DateOfBirth": {
        reset: ["StudyMethod"]
    },
    StudyMethod: {
        reset: ['Region','Course']
    },
    "Student.Gender": {
        reset:[  "SessionIds",
                "Intensity"
    ]
    },
    Region: {
        reset: ["Course"],
        match1: function (a, b) {
            return !b || (!a && !b) || (a && b && a.RegionId == b.RegionId);
        }
    }, 
    Course: {
        reset: [ "SessionIds",
                "Intensity"
    ],
        
        match1: function (a,b) {
            return !b || (!a && !b) || (a && b && a.CourseId == b.CourseId);
        }
    },
    SessionIds: {
        reset: [    "CourseOptionSolutions",
                    "Accommodation",
                    "SelectedUpsellOptions",
                    "SkipUpsell",
                    "TimeZone"]
    },
    CourseOptionSolutions: {
        reset: ["Accommodation"]
    },
    Accommodation: {
        reset: ["SelectedUpsellOptions", "SkipUpsell"]
    }
}


var resetFunc = rest(function(thisArg, props) {
    var initial = thisArg.getDefaultState();
    var resetState = {};

    forEach(props, function(prop) {
        var nullOrDefault = initial.hasOwnProperty(prop) ? initial[prop] : null;
        set(resetState, prop, nullOrDefault);
    });

    return resetState;
}, 1);

export default ApplyWizard;
